<template>
  <v-tooltip top transition="fade">
    <template #activator="{ on, attrs }">
      <div
        class="e-p-1 e-rounded-md e-bg-surface-dark-hover-transparent e-flex e-justify-center e-items-center e-h-6 e-w-6"
        v-bind="attrs"
        v-on="on"
      >
        <EIcon
          :icon="icons[feature]"
          class="camera-feature-icon e-text-surface-dark"
          size="xs"
        />
      </div>
    </template>
    {{ labels[feature] }}
  </v-tooltip>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { EIcon } from "@evercam/ui"
import { CameraFeatureFlag } from "@evercam/shared/types"

export default Vue.extend({
  name: "CameraCardFeatureIcon",
  components: {
    EIcon,
  },
  props: {
    feature: {
      type: String as PropType<CameraFeatureFlag>,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        [CameraFeatureFlag.Segmentation]: "draw-polygon",
        [CameraFeatureFlag.ObjectDetection]: "vector-square",
        [CameraFeatureFlag.SegmentationAutoLabelling]: "comment-dots",
      },
      labels: {
        [CameraFeatureFlag.Segmentation]: "Segmentation",
        [CameraFeatureFlag.ObjectDetection]: "Object Detection",
        [CameraFeatureFlag.SegmentationAutoLabelling]:
          "Segmentation Auto-Labelling",
      },
    }
  },
})
</script>
