var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"site-analytics",class:{ 'site-analytics--active': _vm.siteAnalyticsStore.selectedCamera }},[_c('div',{staticClass:"site-analytics__content"},[_c('v-expand-x-transition',[(_vm.siteAnalyticsStore.selectedCamera)?_c('div',{staticClass:"site-analytics__player"},[_c('nuxt-child')],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"site-analytics__cameras d-flex",class:{
        'pt-2': _vm.siteAnalyticsStore.selectedCamera,
        'flex-column': _vm.siteAnalyticsStore.selectedCamera,
        'flex-wrap': !_vm.siteAnalyticsStore.selectedCamera,
        'pa-3': !_vm.siteAnalyticsStore.selectedCamera,
      }},_vm._l((_vm.siteAnalyticsStore.cameras),function(camera){return _c('nuxt-link',{key:camera.exid,staticClass:"site-analytics__camera-container",attrs:{"to":`/site-analytics/${camera.exid}/timeline`,"tag":"div"},nativeOn:{"click":function($event){return _vm.siteAnalyticsStore.selectCamera(camera.exid)}}},[_c('CameraCard',{staticClass:"e-relative",attrs:{"camera":camera,"token":_vm.accountStore.token,"absolute":false,"show-exid":"","width":_vm.siteAnalyticsStore.selectedCamera ? 135 : 300,"highlighted":camera.exid === _vm.siteAnalyticsStore.selectedCamera?.exid}},[_c('div',{staticClass:"site-analytics__camera-features e-absolute e-p-2 e-flex e-gap-1"},[_vm._l(([
                _vm.CameraFeatureFlag.ObjectDetection,
                _vm.CameraFeatureFlag.Segmentation,
                _vm.CameraFeatureFlag.SegmentationAutoLabelling,
              ]),function(feature){return [(camera.featureFlags.includes(feature))?_c('CameraCardFeatureIcon',{key:feature,attrs:{"feature":feature}}):_vm._e()]})],2)])],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }